import React from "react"
import Layout from "../components/Layout"
import Projects from "../components/Projects"
import Seo from "../components/Seo";
import projects from "../data/projects";

const ProjectsPage = () => {
  return (
    <>
      <Seo title="Projects" />
      <section className="projects-page">
        <Projects projects={projects} title="all projects" />
      </section>
    </>
  )
}

export default ProjectsPage